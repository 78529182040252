import _objectSpread from "D:/Source/Repos/WallinServicesGui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from "vuex";
export default {
  name: "NewsApiArticles",
  components: {//
  },
  props: {
    id: String
  },
  data: function data() {
    return {//
    };
  },
  mounted: function mounted() {//
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    newsApiStatus: function newsApiStatus(state) {
      return state.newsApiStatus;
    }
  })), {}, {
    articles: function articles() {
      var _this = this;

      var source = this.newsApiStatus.find(function (f) {
        return f.id === _this.id;
      });

      if (source) {
        return source.articles;
      }

      return [];
    }
  }),
  methods: {//
  }
};